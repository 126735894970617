






































































































import { Component, Mixins, Prop } from 'vue-property-decorator'
import axios from 'axios'
import snakeCaseKeys from 'snakecase-keys'
import { ValidationObserver } from 'vee-validate'

import FlashMessageMixin from '@/mixins/flashMessageMixin'
import PasswordConfirmField from '@/partials/forms/PasswordConfirmField.vue'

@Component({
  components: {
    PasswordConfirmField,
    ValidationObserver
  }
})
export default class AddPersonToServiceForm extends Mixins(FlashMessageMixin) {
  @Prop({}) person!: ServicePerson
  done = true

  search = ''
  invalidSearch = false
  firstSearch = true
  data = []
  filteredData = []
  selectModalIsOpened = false
  serviceRole = {
    id: '',
    name: ''
  }

  form: Record<string, any> = {
    confirm: '',
    errors: {
      confirm: ''
    }
  }

  selectedUser = {
    id: ''
  }

  get title () {
    const title = {
      specialist: 'Adicionar Especializando',
      visitor: 'Adicionar Visitante',
      secretary: 'Adicionar Secretário',
      chief: 'Editar Chefe de Serviço'
    }

    return title[this.person]
  }

  created () {
    const roleNames = {
      specialist: 'Especializando',
      visitor: 'Visitante',
      secretary: 'Secretaria Centro de Serviço',
      chief: 'Chefe de Serviço'
    }
    axios.get('roles/service/user')
      .then((response) => {
        this.serviceRole = response.data.data.find((serviceRole: Role) => serviceRole.name === roleNames[this.person])
      })
  }

  searchUsers () {
    if (this.search !== '') {
      this.done = false
      axios.get('users', {
        params: {
          search: this.search,
          visitor: this.person === 'visitor' ? true : undefined
        }
      })
        .then(response => {
          console.log('response', response.data.data)
          this.filteredData = this.data = response.data.data
          this.firstSearch = false
        })
        .catch(err => {
          console.log('err', err)
        })
        .finally(() => { this.done = true })
    } else {
      this.invalidSearch = true
    }
  }

  select (user: Record<string, any>) {
    console.log(user)
  }

  filterUsers (searchString: string) {
    this.filteredData = this.data.filter((usuario: Record<string, any>) => {
      let { email, name } = usuario
      email = email.toLowerCase()
      name = name.toLowerCase()
      searchString = searchString.toLowerCase()

      if (email.includes(searchString) || name.includes(searchString)) {
        return usuario
      }
    })
  }

  toggleModal (modalName: string) {
    this.$data[modalName] = !this.$data[modalName]
  }

  submitSelect () {
    if (this.person === 'chief') {
      const submitForm = {
        userId: this.selectedUser.id,
        confirm: this.form.confirm
      }
      axios.patch(`service/${this.$route.params.id}/chief`, snakeCaseKeys(submitForm))
        .then(async (response) => {
          const flashMessage = {
            message: response.data.data,
            isSuccess: true
          }

          await this.toggleModal('selectModalIsOpened')
          this.$router.push({ name: 'ServicesShow', params: { id: this.$route.params.id } }, () => {
            this.setFlashMessage(flashMessage)
          })
        })
        .catch(err => this.handleSubmitError(err))
    } else {
      const submitForm = {
        users: [this.selectedUser.id],
        confirm: this.form.confirm
      }
      axios.post(`service/${this.$route.params.id}/users/role/${this.serviceRole.id}/sync`, submitForm)
        .then(async (response) => {
          const flashMessage = {
            message: response.data.data,
            isSuccess: true
          }

          await this.toggleModal('selectModalIsOpened')
          this.$router.push({ name: 'ServicesShow', params: { id: this.$route.params.id } }, () => {
            this.setFlashMessage(flashMessage)
          })
        })
        .catch(err => this.handleSubmitError(err))
    }
  }

  handleSubmitError (err: any) {
    const response = err.response

    const errors = response.data.errors
    if (errors) {
      Object.entries(errors).map(([key, value]: [string, any]) => {
        this.form.errors[key] = value[0]
      })
    }
  }
}
